import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../../components/layout"

export default function ProjectPage({ data }) {
  console.debug("Project Data: ", data)
  
  const { title, heroImage, body } = data.contentfulProject
  const heroImageData = getImage(heroImage)

  return (
    <Layout>
      <article className="prose prose-stone lg:prose-xl prose-headings:font-heading prose-p:font-body prose-a:text-primary hover:prose-a:text-primary-highlighted mx-auto px-8 py-8">
        <h1 className="text-5xl font-heading">{title}</h1>
        <section className="w-full flex items-center">
          <GatsbyImage
            image={heroImageData}
            alt={heroImage.description || ""}
            layout="fullWidth"
            objectPosition="center"
            objectFit="cover"
            className="rounded-lg aspect-video"
            placeholder="blurred"
          />
        </section>
        <section
          className="py-8"
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
      </article>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!) {
    contentfulProject(id: { eq: $id }) {
      title
      heroImage {
        gatsbyImageData
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
